<script>
// import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
// import PageHeader from "@/components/page-header";
// import axios from "axios";
// import Swal from "sweetalert2";
import HeaderAsesmenHC from "./header_asesmen-hc.vue";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
    HeaderAsesmenHC,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboards",
          href: "/",
        },
        {
          text: "Default",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      transactions: [
        {
          id: "#SK2540",
          name: "Neal Matthews",
          date: "07 Oct, 2019",
          total: "$400",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 1,
        },
        {
          id: "#SK2541",
          name: "Jamal Burnett",
          date: "07 Oct, 2019",
          total: "$380",
          status: "Chargeback",
          payment: ["fa-cc-visa", "Visa"],
          index: 2,
        },
        {
          id: "#SK2542",
          name: "Juan Mitchell",
          date: "06 Oct, 2019",
          total: "$384",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 3,
        },
        {
          id: "#SK2543",
          name: "Barry Dick",
          date: "05 Oct, 2019",
          total: "$412",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 4,
        },
        {
          id: "#SK2544",
          name: "Ronald Taylor",
          date: "04 Oct, 2019",
          total: "$404",
          status: "Refund",
          payment: ["fa-cc-visa", "Visa"],
          index: 5,
        },
        {
          id: "#SK2545",
          name: "Jacob Hunter",
          date: "04 Oct, 2019",
          total: "$392",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 6,
        },
      ],
      modalHistory: false,
    };
  },
  // mounted() {
  //   let self = this;
  //   self.cekAksesMenu();
  // },
  // methods: {
  //   cekAksesMenu() {
  //     let self = this;
  //     var config_axios = {
  //       method: "get",
  //       url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/check-access-menu",
  //       params: {
  //         role_id: self.sessionRoleId,
  //         link_name: self.namePathUrl,
  //       },
  //       headers: {
  //         Accept: "application/json",
  //         Authorization: "Bearer " + localStorage.access_token_peserta,
  //       },
  //     };
  //     axios(config_axios)
  //       .then((response) => {
  //         let response_data = response.data;
  //         let response_data_fix = response_data.data.status_access;
  //         if (response_data.meta.code == 200) {
  //           if (response_data_fix == false) {
  //             let timerInterval;
  //             Swal.fire({
  //               icon: "warning",
  //               title: "Oppss",
  //               text: "Mohon maaf anda tidak diberikan akses pada halaman ini.",
  //               timer: 2000,
  //               timerProgressBar: true,
  //               showCancelButton: false,
  //               showConfirmButton: false,
  //               didOpen: () => {
  //                 timerInterval = setInterval(() => {
  //                   const content = Swal.getContent();
  //                   if (content) {
  //                     const b = content.querySelector("b");
  //                     if (b) {
  //                       b.textContent = Swal.getTimerLeft();
  //                     }
  //                   }
  //                 }, 100);
  //               },
  //               willClose: () => {
  //                 clearInterval(timerInterval);
  //               },
  //             }).then((result) => {
  //               /* Read more about handling dismissals below */
  //               if (result.dismiss === Swal.DismissReason.timer) {
  //                 self.$router.push({ name: "dashboard" });
  //               }
  //             });
  //           }
  //         } else {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Oops...",
  //             text: response_data_fix.data.message,
  //           });
  //         }
  //       })
  //       .catch((errors) => {
  //         console.log(errors);
  //       });
  //   },
  // },
};
</script>

<template>
  <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
  <div class="container">
    <HeaderAsesmenHC />
    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-header bg-primary text-white font-size-16">
            Informasi Soal (Kompetensi)
            <i class="fas fa-expand-arrows-alt font-size-18 pt-1 float-end"></i>
          </div>
          <div class="card-body font-size-14">
            <button type="button" class="btn btn-warning">Petunjuk</button>
            &nbsp;
            <button type="button" class="btn btn-primary">Soal</button>
            <textarea
              class="form-control mt-2"
              rows="10"
              name="text"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="card">
          <div class="card-header bg-primary text-white font-size-16">
            Jawaban
            <i class="fas fa-expand-arrows-alt font-size-18 pt-1 float-end"></i>
          </div>
          <div class="card-body font-size-14">
            <img
              src="@/assets/images/verification-img.png"
              alt
              class="img-fluid"
            />
            <div class="row">
              <div class="col-mb-12">
                <div class="float-end">Last update : 2024-01-05 13:03:12</div>
              </div>
              <div class="col-mb-12"></div>
            </div>
            <div class="row mt-1">
              <div class="col-mb-12">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="modalHistory = true"
                >
                  Lihat History Jawaban
                </button>
                <button type="button" class="btn btn-warning float-end">
                  Simpan Jawaban
                </button>
              </div>
              <b-modal
                v-model="modalHistory"
                title="History Jawaban"
                hide-footer
              >
              <table
              class="table align-middle table-nowrap table-striped table-bordered font-size-14 table-sm"
            >
              <thead class="table-primary">
                <tr>
                  <td>Tanggal Simpan</td>
                  <td>Aksi</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td>
                    <button type="button" class="btn btn-primary btn-sm">
                      Lihat Jawaban
                    </button>
                    &nbsp;
                    <button type="button" class="btn btn-danger btn-sm">
                      Restore
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
              </b-modal>
              <div class="col-mb-12"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
  <!-- </Layout> -->
</template>
<style scoped>
.border-none {
  border-style: hidden;
}
</style>